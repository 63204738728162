<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>系统管理</BreadcrumbItem>
                <BreadcrumbItem>日志管理</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem label="关键字" :label-width="75">
                    <Input v-model="filter.operatorName" placeholder="用户名/操作描述/操作内容" clearable />
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="getOperationlogs('0')">查询</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :loading="loading" :data="operationlogs"> </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页 </Col>
                    <Col class="pagenation" span="18">
                    <Page :page-size="pageSize" :total="total" :current="pageNum" @on-change="changePage" @on-page-size-change="changPageSize" :page-size-opts="[15, 20, 50, 100]" show-sizer show-elevator />
                    </Col>
                </Row>
            </div>
        </div>
    </div>
</template>

<script>
import { reqGetOperationLogList } from "../../../api/system-api";
import permission from "../../../common/permission";

export default {
    name: "TableView",
    data() {
        return {
            filter: {
                operatorName: "",
            },
            columns: [
                {
                    title: "序号",
                    type: "index",
                    key: "index",
                    width: 80,
                },
                {
                    title: "用户名",
                    key: "operatorName",
                    width: 150,
                },
                {
                    title: "操作描述",
                    key: "story",
                    width: 200,
                },
                {
                    title: "操作内容",
                    key: "content",
                    minWidth: 420,
                },
                {
                    title: "日志生成时间",
                    key: "createTime",
                    width: 200,
                },
            ],
            permissionButton: {
                add: false,
                edit: false,
                view: false,
            },
            total: 0,
            pages: 1,
            pageNum: 1,
            pageSize: 20,
            loading: false,
            operationlogs: [],
        };
    },
    methods: {
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getOperationlogs();
        },
        //每页显示的数据条数
        changPageSize(index) {
            //实时获取当前需要显示的条数
            this.pageSize = index;
            this.getOperationlogs();
        },
        //获取分页列表
        getOperationlogs(status) {
            let that = this;
            that.loading = true;
            let para = {
                pageNum: that.pageNum,
                pageSize: that.pageSize,
                operatorName: that.filter.operatorName,
            };
            reqGetOperationLogList(para).then((res) => {
                that.loading = false;
                that.total = res.data.data.total;
                that.operationlogs = res.data.data.records;
                that.pages = res.data.data.page;
            });
        },
    },
    mounted() {
        permission.getPermissionButton(
            this.$route.query.permissionId,
            this.permissionButton
        );
        this.getOperationlogs();
    },
};
</script>
